/**
 * Represents a LoyaltyModal object.
 * @class
 */
class LoyaltyModal {
	constructor() {
		this.container = null;
		this.maxPoints = null;
		this.myPoints = null;
		this.maxRemainingPoints = null;
		this.pointRatio = null;
		this.input = null;
		this.button = null;
		this.data = {};
	}

	/**
	 * Initializes the loyalty redeem functionality.
	 */
	init() {
		this.container = document.querySelector(".myw-redeem-modal");

		if (!this.container) return;

		this.maxPoints = this.container.querySelector("[max-points]");
		this.myPoints = this.container.querySelector("[my-points]");
		this.maxRemainingPoints = this.container.querySelector("[max-remaining-points]");
		this.pointRatio = this.container.querySelector("[point-ratio]");
		this.input = this.container.querySelector("input");
		this.button = this.container.querySelector("button");

		const selectedPoints = this.input.value ? Number(this.input.value) : 0;

		this.data = {
			maxAvailitbPoints: Number(this.container.dataset.maxPoints),
			myPoints: Number(this.container.dataset.myPoints),
			pointRatio: Number(this.container.dataset.pointRatio),
			selectedPoints,
			maxRemainingPoints: Number(this.container.dataset.myPoints) - selectedPoints,
		};

		this.fillData();
		this.setupEventListeners();
	}

	/**
	 * Sets up event listeners for the loyalty redeem functionality.
	 */
	setupEventListeners() {
		this.button.addEventListener("click", (event) => this.submit(event));

		this.input.addEventListener("input", (event) => {
			const val = Math.min(Number(event.currentTarget.value), Math.min(this.data.myPoints, this.data.maxAvailitbPoints));

			this.data.selectedPoints = val;
			this.input.value = val;

			this.resetButton();
			this.calcPoints();
		});
	}

	/**
	 * Fills the data in the loyalty redeem section.
	 */
	fillData() {
		if (!this.data) return;

		this.maxPoints.textContent = this.data.maxAvailitbPoints;
		this.myPoints.textContent = this.data.myPoints;
		this.maxRemainingPoints.textContent = this.data.maxRemainingPoints;
	}

	/**
	 * Calculates the maximum remaining points based on the current points and selected points.
	 */
	calcPoints() {
		this.data.maxRemainingPoints = Math.max(0, this.data.myPoints - this.data.selectedPoints);
		this.maxRemainingPoints.textContent = this.data.maxRemainingPoints;
	}

	/**
	 * Submits the loyalty redemption request.
	 * @param {Event} event - The event object.
	 * @returns {void}
	 */
	submit(event) {
		event.preventDefault();
		this.button.disabled = true;

		const data = {
			action: "simtec_gurado_loyalty_redeem_points",
			points: this.data.selectedPoints,
		};

		jQuery.post(gurado_ajax.ajaxurl, data, (response) => {
			const parsedResponse = JSON.parse(response);

			if (parsedResponse.success) {
				window.location.reload();
			}
		});

		this.resetButton();
		this.closeModal();
	}

	/**
	 * Resets the button state by removing the "disabled" class and enabling the button.
	 */
	resetButton() {
		if (this.button.classList.contains("disabled")) {
			this.button.classList.remove("disabled");
		}
		this.button.disabled = false;
	}

	/**
	 * Closes the modal.
	 */
	closeModal() {
		this.container?.close();
	}

	/**
	 * Retrieves the selected points.
	 *
	 * @returns {number} The selected points.
	 */
	getPoints() {
		return this.data.selectedPoints;
	}

	/**
	 * Retrieves the remaining points.
	 *
	 * @returns {number} The maximum remaining points.
	 */
	getRemainingPoints() {
		return this.data.maxRemainingPoints;
	}
}

export { LoyaltyRedeem, LoyaltyModal };
