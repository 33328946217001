const ShowFormError = (form, error) => {

	const formError = form.querySelector(".myw-form-toast");
	formError.querySelector("p").innerHTML = formatHTML(error);

	return (formError.style.display = "flex");
};

const HideFormError = (form) => {
	const formError = form.querySelector(".myw-form-toast");

	return (formError.style.display = "none");
};

const formatHTML = (html) => {
	return html.replace(/&gt;/g, ">").replace(/&lt;/g, "<").replace(/&amp;/g, "&");
};

export { ShowFormError, HideFormError };
