const ToggleCheckoutProducts = () => {
	const checkoutItems = document.querySelectorAll(".myw-checkout-item__show");

	checkoutItems.forEach((checkoutItem) => {
		checkoutItem.addEventListener("click", () => {
			checkoutItem.closest(".myw-checkout-item").querySelector(".myw-checkout-item-products-long").classList.toggle("d-none");
			checkoutItem.closest(".myw-checkout-item").querySelector(".myw-checkout-item__products").classList.toggle("d-none");

			changeNaming(checkoutItem);
		});
	});

    /**
     * Changes the naming of a checkout item based on its current state.
     * 
     * @param {HTMLElement} checkoutItem - The checkout item element.
     * @returns {void}
     */
	const changeNaming = (checkoutItem) => {
		let checkoutItemProductsLong = checkoutItem.closest(".myw-checkout-item").querySelector(".myw-checkout-item-products-long");

		if (checkoutItemProductsLong?.classList.contains("d-none")) {
			checkoutItem.textContent = checkoutItem.getAttribute("data-hide-text");
		} else {
			checkoutItem.textContent = checkoutItem.getAttribute("data-show-text");
		}
	};
};

export { ToggleCheckoutProducts };
