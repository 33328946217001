class NoticeManager {
	constructor(selector, removeDelay = 5000) {
		this.selector = selector;
		this.removeDelay = removeDelay;
		this.observer = new MutationObserver(this.handleMutations.bind(this));
	}

	/**
	 * Starts observing the changes in the document body.
	 */
	startObserving() {
		this.observer.observe(document.body, { childList: true, subtree: true });
	}

	/**
	 * Handles mutations in the DOM.
	 *
	 * @param {MutationRecord[]} mutations - The list of mutations to handle.
	 */
	handleMutations(mutations) {
		mutations.forEach((mutation) => {
			if (mutation.type === "childList") {
				mutation.addedNodes.forEach((node) => {
					if (node?.nodeType === Node.ELEMENT_NODE && (node.classList.contains(this.selector) || this.hasNoticeChild(node))) {
						this.startRemovingNotice(node);
					}
				});
			}
		});
	}

	/**
	 * Checks if a given node or any of its children has a specific CSS class.
	 *
	 * @param {HTMLElement} node - The node to check.
	 * @returns {boolean} - True if the node or any of its children has the specified CSS class, false otherwise.
	 */
	hasNoticeChild(node) {
		if (node.classList.contains(this.selector)) {
			return true;
		}

		for (const child of node.children) {
			if (this.hasNoticeChild(child)) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Starts removing notices from the specified container at a regular interval.
	 * @param {HTMLElement} notice - The container element from which to remove notices.
	 * @returns {void}
	 */
	startRemovingNotice(notice) {
		setInterval(() => this.removeOldestNotice(notice), this.removeDelay);
	}

	/**
	 * Starts removing a notice at a specified interval.
	 *
	 * @param {Object} notice - The notice to be removed.
	 * @returns {void}
	 */
	removeOldestNotice(notice) {
		if (!notice) return;

		notice.classList.add("fade-out");
		notice.addEventListener("transitionend", () => notice.remove(), { once: true });
	}
}

export { NoticeManager };
