/**
 * Class representing a WooCommerce Cart Updater.
 * @class
 */
class WooCommerceCartUpdater {
	constructor() {
		this.init();
	}

    /**
     * Initializes the cart functionality.
     * 
     * @returns {void}
     */
	init() {
		if (!this.checkIfCartPage()) return;

		this.bindQuantityChange();
		this.addOvserver(() => this.bindQuantityChange());
	}

	/**
	 * Binds the quantity change event to the input fields in the cart form.
	 * Whenever the quantity of an item is changed, it triggers the updateCart function after a debounce of 500 milliseconds.
	 */
	bindQuantityChange() {
		document.querySelectorAll(".woocommerce-cart-form input.js-diy-counter-input").forEach((input) => {
			input.addEventListener(
				"change",
				this.debounce(() => this.updateCart(), 500)
			);
		});
	}

	/**
	 * Updates the cart by clicking the update button.
	 *
	 * @param {HTMLElement} inputElement - The input element triggering the update.
	 */
	updateCart(inputElement) {
		const updateButton = document.querySelector('.woocommerce-cart-form button[name="update_cart"]');

		if (!updateButton) return;

		updateButton.removeAttribute("disabled");
		updateButton.click();
	}

	/**
	 * Debounces a callback function.
	 *
	 * @param {Function} callback - The callback function to be debounced.
	 * @param {number} [delay=300] - The delay in milliseconds before invoking the callback.
	 * @returns {Function} - The debounced function.
	 */
	debounce(callback, delay = 300) {
		let timeoutId;

		return function (...args) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				callback.apply(this, args);
			}, delay);
		};
	}

	/**
	 * Checks if the current page is the cart page.
	 * @returns {boolean} True if the current page is the cart page, false otherwise.
	 */
	checkIfCartPage() {
		return document.querySelector(".woocommerce-cart-form") ? true : false;
	}

    /**
     * Adds an observer to the document body to detect changes in the DOM.
     * @param {Function} callback - The callback function to be executed when a change is detected.
     */
	addOvserver(callback) {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				mutation.addedNodes.forEach((node) => {
					if (node.nodeType === 1 && node.classList.contains("woocommerce-cart-form")) {
						callback();
					}
				});
			});
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	}
}

export { WooCommerceCartUpdater };
