/**
 * Counter component.
 * @function Counter
 * @description Initializes the Counter component and sets up mutation observer to handle dynamic additions of Counter elements.
 */
const Counter = () => {
	const Counter = document.querySelectorAll(".myw-increment");
	Counter.forEach((element) => CreateCounter(element));

	const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			mutation.addedNodes.forEach((node) => {
				if (node.nodeType === 1 && node.classList.contains("myw-increment")) {
					CreateCounter(node);
				} else if (node.nodeType === 1) {
					// If it's an element, check its children
					node.querySelectorAll(".myw-increment").forEach((child) => CreateCounter(child));
				}
			});
		});
	});

	observer.observe(document.body, {
		childList: true,
		subtree: true,
	});
};

/**
 * Creates a counter for the given element.
 *
 * @param {HTMLElement} element - The element to create the counter for.
 * @returns {void}
 */
const CreateCounter = (element) => {
	if (!element) return;
	if (element.dataset.counterInit) return; // Counter wurde schon init

	const MaxValue = element.dataset.maxCount === "-1" ? Infinity : parseInt(element.dataset.maxCount);
	const MinValue = element.dataset.minCount === undefined ? 1 : parseInt(element.dataset.minCount);
	const Increment = 1;

	let input = element.querySelector("input");
	let buttonMinus = element.querySelector(".js-diy-counter-minus");
	let buttonPlus = element.querySelector(".js-diy-counter-plus");

	input.setAttribute("min", MinValue);
	input.setAttribute("max", MaxValue);

	buttonMinus.addEventListener("click", (e) => {
		e.preventDefault();
		let value = parseInt(input.value);

		if (value <= MinValue) return;
		input.value = value - Increment;
		input.dispatchEvent(new Event("change"));
	});

	buttonPlus.addEventListener("click", (e) => {
		e.preventDefault();
		let value = parseInt(input.value);

		if (value >= MaxValue) return;
		input.value = value + Increment;
		input.dispatchEvent(new Event("change"));
	});

	element.dataset.counterInit = true;
};

export { Counter, CreateCounter };
