/**
 * VoucherSelection Class
 */
class VoucherSelection {
	constructor() {
		this.container = document.querySelector(".myw-vouchers");
		this.items = document.querySelectorAll(".myw-vouchers .myw-voucher");
		this.preSelected = [];
		this.data = {
			maxAllowed: parseInt(this.container?.getAttribute("voucher-max-allowed")) || 0,
			selected: 0,
		};
		this.disabledClass = "myw-voucher--disabled";
		this.onChange = () => {};
	}

	init(params) {
		if (!this.container || !this.items.length) return;

		this.data.maxAllowed = params.maxAllowed || this.data.maxAllowed;
		this.preSelected = params.preSelected || this.container.getAttribute("voucher-preselected")?.split(",") || [];
		this.onChange = params.onChange || this.onChange;

		if (this.preSelected.length) {
			this.data.maxAllowed -= this.preSelected.length;
		}

		this.preSelectVouchers();
		this.itemEvent();
	}

	/**
	 * Pre-selects vouchers based on the provided codes.
	 *
	 * @returns {void}
	 */
	preSelectVouchers() {
		if (!this.preSelected.length) return;
		if (this.data.maxAllowed < this.preSelected.length) {
			console.warn("Too many vouchers given");
			return;
		}

		this.preSelected.forEach((code) => {
			const selected = this.container.querySelector(`.myw-voucher[voucher-code="${code}"]`);
			if (!selected) return;

			selected.setAttribute("voucher-selected", "true");
			selected.setAttribute("voucher-blocked", "true");
		});

		this.data.selected = this.getSelectedItems().length;
		this.blurOnLimit();
	}

	/**
	 * Attaches click event listeners to each item in the selection.
	 * Updates the selected state of the item and triggers the onChange callback.
	 * @memberof voucherSelection
	 * @function itemEvent
	 * @returns {void}
	 */
	itemEvent() {
		this.items.forEach((item) => {
			item.addEventListener("click", () => {
				const isSelected = item.getAttribute("voucher-selected") === "true";

				if (this.data.selected === this.data.maxAllowed && !isSelected) return;

				item.setAttribute("voucher-selected", !isSelected);
				const checkbox = item.querySelector("input[type=checkbox]");
				if (checkbox) {
					checkbox.checked = !isSelected;
				}

				this.data.selected = this.getSelectedItems().length;

				this.onChange({
					selectedItems: this.getSelectedItems(),
					selectedVouchers: this.getSelectedVouchers(),
					length: this.getSelectedItems().length,
				});

				this.blurOnLimit();
			});
		});
	}

	/**
	 * Retrieves the selected items from the container.
	 *
	 * @returns {Array<Element>} An array of elements representing the selected items.
	 */
	getSelectedItems() {
		return [...this.container.querySelectorAll('.myw-voucher[voucher-selected="true"]')];
	}

	/**
	 * Retrieves all the voucher elements that are not selected.
	 *
	 * @returns {Array<Element>} An array of voucher elements that are not selected.
	 */
	getNotSelectedItems() {
		return [...this.container.querySelectorAll('.myw-voucher[voucher-selected="false"]')];
	}

	/**
	 * Retrieves an item by voucher code.
	 *
	 * @param {string} voucher - The voucher code to search for.
	 * @returns {Element|null} - The DOM element with the matching voucher code, or null if not found.
	 */
	getItemByVoucher(voucher) {
		if (!voucher) return null;
		return this.container.querySelector(`.myw-voucher[voucher-code="${voucher}"]`);
	}

	/**
	 * Retrieves the selected vouchers.
	 *
	 * @returns {string[]} An array of voucher codes representing the selected vouchers.
	 */
	getSelectedVouchers() {
		return this.getSelectedItems().map((item) => item.getAttribute("voucher-code")) || [];
	}

	/**
	 * Sets an error state for a voucher item.
	 *
	 * @param {string} voucher - The voucher to set the error state for.
	 * @returns {void}
	 */
	setItemError(voucher) {
		if (!voucher) return;

		const item = this.getItemByVoucher(voucher);
		item.setAttribute("voucher-selected", "error");

		this.data.selected = this.getSelectedVouchers().length;
	}

	/**
	 * Clears the error state of the selected vouchers.
	 */
	clearItemError() {
		const errors = this.container.querySelectorAll('.myw-voucher[voucher-selected="error"]');
		const errorMessages = this.container.querySelectorAll(".myw-voucher__error");

		errorMessages.forEach((msg) => msg.remove());
		errors.forEach((error) => error.setAttribute("voucher-selected", "false"));

		this.data.selected = this.getSelectedVouchers().length;
	}

	/**
	 * Blurs the items that exceed the maximum allowed selection.
	 */
	blurOnLimit() {
		const items = this.getNotSelectedItems();
		items.forEach((item) => item.classList.remove(this.disabledClass));

		if (this.getSelectedItems().length >= this.data.maxAllowed) {
			items.forEach((item) => item.classList.add(this.disabledClass));
		}
	}
}

/**
 * SessionStorage Handler
 */
class VoucherData {
	constructor() {
		this.name = "gurado_voucher_error";
		this.data = JSON.parse(sessionStorage.getItem(this.name)) || [];
	}

	/**
	 * Initializes the voucher selection.
	 *
	 * @param {Function} callback - The callback function to be executed after initialization.
	 */
	init(callback) {
		if (callback) callback();
	}

	/**
	 * Saves the provided data to the session storage.
	 *
	 * @param {Object} data - The data to be saved.
	 */
	saveData(data) {
		if (!data) return;

		const currentData = [...this.data];
		currentData.push(data);

		const uniqueData = currentData.filter((obj, index) => currentData.findIndex((item) => item.code === obj.code) === index);

		sessionStorage.setItem(this.name, JSON.stringify(uniqueData));
		this.updateData();
	}

	/**
	 * Updates the data by parsing the JSON stored in the session storage.
	 */
	updateData() {
		this.data = JSON.parse(sessionStorage.getItem(this.name)) || [];
	}

	/**
	 * Retrieves the data as a JSON string.
	 * @returns {string} The data as a JSON string.
	 */
	getData() {
		return JSON.stringify(this.data);
	}

	/**
	 * Clears the data and updates the session storage.
	 */
	clearData() {
		this.data = [];
		sessionStorage.setItem(this.name, JSON.stringify([]));
	}
}

// Main Logic
document.addEventListener("DOMContentLoaded", () => {
	const button = document.querySelector(".js-apply-vouchers");
	const voucherSelection = new VoucherSelection();
	const voucherData = new VoucherData();

	const getAlreadySelectedVouchers = () => {
		const selectedVouchers = [];
		const alreadySelectedVouchers = document.querySelectorAll("*[data-coupon]");

		alreadySelectedVouchers.forEach((voucher) => {
			selectedVouchers.push(voucher.dataset.coupon);
		});

		return selectedVouchers;
	};

	const createErrorElements = (data) => {
		if (!data) return;
		voucherData.clearData();

		const TIMEOUT = 5000;
		const formContainer = document.querySelector("#simtec_gurado_form");
		const errorContainer = document.querySelector("#coupon-error-gurado");

		formContainer.classList.add("open");

		data.forEach((element) => {
			const errorElement = document.createElement("span");
			errorElement.className = "error";
			errorElement.innerHTML = `<span>${element.code}:</span> ${element.response.message}`;

			return errorContainer.appendChild(errorElement);
		});

		errorContainer.style.display = "block";
		return errorContainer.querySelectorAll(".error")?.forEach((el, index) => {
			setTimeout(() => {
				el.remove();
				errorContainer.style.display = "none";
			}, TIMEOUT);
		});
	};

	const guradoVoucherRequest = (e) => {
		e.preventDefault();

		document.body.classList.add("gurado-loader");

		const codes = voucherSelection.getSelectedVouchers();
		if (!codes.length) return;

		let withValidVoucher = false;
		const requests = codes.map((code) => {
			return fetch(gurado_ajax.ajaxurl, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					action: "simtec_gurado_coupon_check",
					couponname: code,
				}),
			})
				.then((response) => response.json())
				.then((decodedRes) => {
					if (decodedRes.status !== "FAILURE" || ["Code wurde bereits eingelöst", "Der Gutschein wurde bereits hinzugefügt."].includes(decodedRes.message)) {
						withValidVoucher = true;
					} else {
						voucherData.saveData({ code, response: decodedRes });
						voucherSelection.setItemError(code);
					}
				});
		});

		Promise.all(requests).then(() => {
			document.body.classList.remove("gurado-loader");
			document.documentElement.classList.remove("noscroll");

			if (withValidVoucher) {
				window.location = gurado_ajax.currentpage;
			} else {
				const errors = JSON.parse(voucherData.getData());
				createErrorElements(errors);
			}
		});
	};

	voucherData.init(() => {
		if (voucherData.data.length) {
			const data = JSON.parse(voucherData.getData());
			createErrorElements(data);
		}
	});

	voucherSelection.init({
		preSelected: getAlreadySelectedVouchers(),
		onChange: (e) => {
			voucherSelection.clearItemError();
			if (!voucherSelection.data.selected) {
				button.classList.add("disabled");
			} else {
				button.classList.remove("disabled");
			}
		},
	});

	if (!voucherSelection.items.length && button) {
		button.classList.add("disabled");
	}

	button?.addEventListener("click", (e) => guradoVoucherRequest(e));
});
