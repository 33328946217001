/**
 * Represents a dialog component.
 * @constructor
 */
const Dialog = () => {
	const dialogTrigger = document.querySelectorAll("*[dialog][dialog-action]");

	if (!dialogTrigger.length) return;

	dialogTrigger.forEach((trigger) => {
		trigger.addEventListener("click", (e) => {
			e.preventDefault();
			dialogAction(e.target);
		});
	});

	/**
	 * Performs the specified action on the dialog element.
	 * @param {HTMLElement} element - The element triggering the action.
	 */
	const dialogAction = (element) => {
		const action = element.getAttribute("dialog-action");
		const dialogToOpen = document.querySelector(
			`dialog#${element.getAttribute("dialog")}`
		);

		if (!dialogToOpen) return;

		if (action === "open") {
			return dialogToOpen.showModal();
		}

		return dialogToOpen.close();
	};
};

/**
 * Creates a dialog element with the specified properties.
 *
 * @param {Object} args - The arguments for creating the dialog.
 * @returns {HTMLElement} - The created dialog element.
 */
const CreateDialog = (args) => {
	const { id, headline, message, button, onClose = () => {} } = args;

	if (!id) return;

	const dialog = document.createElement("dialog");
	dialog.id = id;
	dialog.classList.add("myw-dialog");

	dialog.innerHTML = `
        <div class="myw-dialog__header">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.286 45.286" dialog="${id}" dialog-action="close">
                <path fill="currentColor" d="m40.703 0-18.06 18.06L4.583 0 .001 4.582l18.06 18.06L0 40.703l4.583 4.583 18.06-18.06 18.06 18.06 4.582-4.582-18.06-18.06L45.286 4.583z" data-name="Pfad 664"></path>
            </svg>
        </div>
        <div class="myw-dialog__body">
            <h2 class="myw-display myw-display--2 mt-0 mb-6 text-center">
                ${headline}
            </h2>
            <p class="text-center">${message}</p>
            <button class="myw-button myw-button--secondary mt-8 mb-8" dialog="${id}" dialog-action="close">
                ${button}
            </button>
        </div>
    `;

    dialog.querySelector("button").addEventListener("click", onClose);
	document.body.appendChild(dialog);

	Dialog();
	return dialog.showModal();
};

export { Dialog, CreateDialog };
