// Load all styles
import "/assets/scss/main.scss";

import { Dialog, CreateDialog } from "./dialog";
import { ShowFormError, HideFormError } from "./error";
import { Counter } from "./counter";
import { WooCommerceCartUpdater } from "./cart";
import { LoyaltyModal } from "./loyalty/loyalty-redeem";
import { InitDropdowns } from "./dropdown";
import { NoticeManager } from "./notices";
import { ToggleCheckoutProducts } from "./ToggleCheckoutProducts";

import "./voucherSelection";
import "./loyalty/loyalty-navigation-overlay";

document.addEventListener("DOMContentLoaded", () => {
	Counter();
	Dialog();
	InitDropdowns();
	ToggleCheckoutProducts();

	new WooCommerceCartUpdater();

	const noticeManager = new NoticeManager("notice", 5000);
	noticeManager.startObserving();

	window.loyaltyModal = new LoyaltyModal();
	window.loyaltyModal.init();
});

const disableForm = (form) => {
	if (!form) return;

	const inputs = form.querySelectorAll("input, select, textarea");

	if (form.querySelector("button[type=submit]")) {
		form.querySelector("button[type=submit]").classList.toggle("d-none");
	}

	inputs.forEach((input) => {
		if (input.hasAttribute("data-persist") || input.type === "hidden") {
			return;
		}

		return input.toggleAttribute("disabled");
	});
};

// Login form
document.querySelector("form#accountLogin")?.addEventListener("submit", (e) => {
	e.preventDefault();

	// reset error message
	HideFormError(e.target);

	const formData = new FormData(e.target);
	const data = {
		action: "woocommerce_login_ajax",
		security: formData.get("woocommerce-login-nonce"),
		user_login: formData.get("login"),
		password: formData.get("password"),
	};

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		success: (data) => {
			if (data.success) {
				return (window.location.href = data.redirect);
			}

			return ShowFormError(e.target, data.message);
		},
	});
});

// Edit user billing address
document.querySelector("form#editBillingAddress")?.addEventListener("submit", (e) => {
	e.preventDefault();

	const dialog = document.querySelector("#errorDialog");
	const formData = new FormData(e.target);
	const data = {
		action: "update_customer_address",
		type: "billing",
		security: formData.get("woocommerce-edit-address-nonce"),
	};

	// put all formData entries into data object
	for (let [key, value] of formData.entries()) {
		data[key] = value;
	}

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		success: (data) => {
			if (data.success) {
				return disableForm(e.target);
			}

			ShowFormError(e.target, data.message);
			return dialog.showModal();
		},
	});
});

// Resend loyalty email verification
document.querySelector(".js-resend-loyalty")?.addEventListener("click", (e) => {
	e.preventDefault();

	const data = {
		action: "loyalty_resend_confirmation_email_action",
	};

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		complete: () => {
			return document.querySelector("#resendLoyaltyVerification").close();
		},
	});
});

// Edit user shipping address
document.querySelector("form#editShippingAddress")?.addEventListener("submit", (e) => {
	e.preventDefault();

	const dialog = document.querySelector("#errorDialog");
	const formData = new FormData(e.target);
	const data = {
		action: "update_customer_address",
		type: "shipping",
		security: formData.get("woocommerce-edit-address-nonce"),
	};

	// put all formData entries into data object
	for (let [key, value] of formData.entries()) {
		data[key] = value;
	}

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		success: (data) => {
			if (data.success) {
				return disableForm(e.target);
			}

			ShowFormError(e.target, data.message);
			return dialog.showModal();
		},
	});
});

// Password reset form
document.querySelector("form#accountResetPassword")?.addEventListener("submit", (e) => {
	e.preventDefault();

	const formData = new FormData(e.target);
	const data = {
		action: "woocommerce_reset_password_ajax",
		security: formData.get("woocommerce-lost-password-nonce"),
		user_login: formData.get("user_login"),
		wc_reset_password: formData.get("wc_reset_password"),
	};

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		complete: () => {
			e.target.reset();
			return document.querySelector("#lostPassword").close();
		},
	});
});

// Register form
document.querySelector("form#accountRegister")?.addEventListener("submit", (e) => {
	e.preventDefault();

	// reset error message
	HideFormError(e.target);

	const dialog = document.querySelector("#errorDialog");
	const formData = new FormData(e.target);
	const data = {
		action: "loyalty_register_action",
		security: formData.get("woocommerce-register-nonce"),
		no_redirect: true,
	};

	// put all formData entries into data object
	for (let [key, value] of formData.entries()) {
		data[key] = value;
	}

	// check if passwords match
	if (data.reg_password !== data.passwordRepeat) {
		ShowFormError(e.target, "Die Passwörter stimmen nicht überein.");
		return dialog.showModal();
	}

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		success: (data) => {
			if (data.success) {
				const reloadTo = data.redirect ? data.redirect : window.location.href;
				return (window.location.href = reloadTo);
			}

			ShowFormError(e.target, data.message);
			return dialog.showModal();
		},
	});
});

// check if Registger form is valid if not disable submit button if valid enable it
document.querySelector("form#accountRegister")?.addEventListener("input", (e) => {
	const form = document.querySelector("form#accountRegister");
	if (!form) return;

	const submit = form.querySelector("button[type=submit]");

	if (form.checkValidity()) {
		submit.removeAttribute("disabled");
	} else {
		submit.setAttribute("disabled", "true");
	}
});

// toggle accountRegister form visibility
document.querySelector("button[name='open_register']")?.addEventListener("click", () => {
	document.querySelector("#accountRegister").classList.toggle("d-none");
});

// toggle fields in form via button click
document.querySelectorAll("*[data-edit]")?.forEach((button) => {
	button.addEventListener("click", (e) => {
		e.preventDefault();
		disableForm(e.target.closest("form"));
	});
});

// button with data-back attribute to go back to previous page in history
document.querySelectorAll("*[data-back]")?.forEach((button) => {
	button.addEventListener("click", () => {
		window.history.back();
	});
});

document.querySelector("input#subscribe_to_loyalty")?.addEventListener("change", (e) => {
	const button = document.querySelector(".js-register-loyalty-subsequently");

	if (!e.target.checked) return button.setAttribute("disabled", "true");
	return button.removeAttribute("disabled");
});

document.querySelector("form#subscribeToLoyalty")?.addEventListener("submit", (e) => {
	e.preventDefault();

	const formData = new FormData(e.target);
	const data = {
		action: "subscribe_to_loyalty_action",
		security: formData.get("subscribe_to_loyalty_nonce"),
	};

	// put all formData entries into data object
	for (let [key, value] of formData.entries()) {
		data[key] = value;
	}

	// disable submit button
	e.target.querySelector("button[type=submit]").setAttribute("disabled", "true");

	jQuery.ajax({
		type: "POST",
		dataType: "json",
		url: globals.api_url,
		data: data,
		success: (res) => {
			document.querySelector("#subscribeToLoyalty").close();

			if (res.success) {
				return CreateDialog({
					id: "successSubscriptionToLoyalty",
					headline: res.data.headline,
					message: res.data.message,
					button: "OK",
					onClose: () => {
						location.reload();
					},
				});
			}

			CreateDialog({
				id: "errorSubscriptionToLoyalty",
				headline: res.data.headline,
				message: res.data.message,
				button: "OK",
			});
		},
	});
});
