class Dropdown {
	constructor(element) {
		this.element = element;

		this.button = this.element.querySelector(".myw-dropdown__trigger");
		this.content = this.element.querySelector(".myw-dropdown__content");

		this.button.addEventListener("click", (e) => {
			e.preventDefault();

			this.toggleContent();
		});
	}

	toggleContent() {
		this.element.classList.toggle("open");
	}
}

const InitDropdowns = () => {
	document.querySelectorAll(".myw-dropdown").forEach((dropdown) => {
		new Dropdown(dropdown);
	});
};

export { Dropdown, InitDropdowns };
