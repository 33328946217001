const formatEuroStringToFloat = (price) => {
	if (!price) return;
	if (typeof price == "string") return parseFloat(price.replace(/\€/g, "")).toFixed(2);

	return parseFloat(price).toFixed(2);
};

const formatToEuro = (price) => {
	if (!price) return;

	return parseFloat(price).toLocaleString("de-DE", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: 2,
	});
};

const pluralize = (count, singular, plural) => {
	return count !== 1 ? plural : singular;
};

const getWooTitleId = (salutation) => {
	if(!salutation) return;
	let id = 0;

	switch(salutation) {
		case 'Herr':
			id = 1;
			break;
		case 'Frau':
			id = 2;
			break;
		case 'Divers':
			id = 3;
			break;
		default:
			id = salutation;
	}

	return id;
}

const formateMywDate = (dateString) => {
	return dateString;
}

const generatePassword = () => {
	const length = 16;
	const charset = "@#$&*0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$&*0123456789abcdefghijklmnopqrstuvwxyz";
	let password = "";

	for (let i = 0, n = charset.length; i < length; ++i) {
		password += charset.charAt(Math.floor(Math.random() * n));
	}

	return password;
};

export { formatEuroStringToFloat, formatToEuro, pluralize, error, loading, generatePassword, getWooTitleId, formateMywDate };
